// src/components/DayPass.tsx

import { AuthProvider } from "src/context/AuthContext";
import DisclaimerBody from "./DisclaimerBody";

type Props = {
  lng: string;
};

const DisclaimerWrapper = ({ lng }: Props) => {
  return (
    <AuthProvider>
      <DisclaimerBody lng={lng} />
    </AuthProvider>
  );
};

export default DisclaimerWrapper;
